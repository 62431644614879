import React, { useState, useEffect } from 'react';
import Navbar from '../Components/Navbar/Navbar';
import './CSS/HomePage.css';
import '../Components/StartupSangamPopup/StartupSangamPopup.css';
import Announcements from '../Components/Announcements/Announcements';
import Footer from '../Components/Footer/Footer';
import FocusedAreas from '../Components/FocusedAreas/FocusedAreas';
import Carousel from '../Components/Carousel/Carousel';
import About from '../Components/About/About';
import OurStartups from '../Components/OurStartups/OurStartups';
import sl1 from '../Components/Assets/cw.jpg';
import sl2 from "../Components/Assets/cashcry.jpg";
import sl3 from "../Components/Assets/darjie.jpg";
import sl4 from "../Components/Assets/ge.jpg";
import sl5 from "../Components/Assets/shipizens.jpg";
import sl6 from "../Components/Assets/ie.jpg";
import sl7 from "../Components/Assets/ghs.jpg";
import sl8 from "../Components/Assets/soboroso.jpg";
import sl9 from "../Components/Assets/sl9.jpg";
import sl10 from '../Components/Assets/Xub.jpg';
import pis1 from "../Components/Assets/pis1.jpg";
import pis2 from "../Components/Assets/pis2.jpg";
import pis3 from "../Components/Assets/pis3.jpg";
import pis4 from "../Components/Assets/pis4.png";
import pis5 from "../Components/Assets/pis5.jpg";
import pis6 from "../Components/Assets/pis6.jpg";
import pis7 from "../Components/Assets/pis7.jpg";
import pis8 from "../Components/Assets/pis8.jpg";
import pis9 from "../Components/Assets/pis9.jpg";
import StartupSangamPopup from '../Components/StartupSangamPopup/page';

const HomePage = () => {
  // State to manage popup visibility
  const [showPopup, setShowPopup] = useState(false);

  // useEffect to control the popup display
  useEffect(() => {
    setShowPopup(true);
    window.scrollTo(0, 0);
  }, []);

  // Function to close popup
  const closePopup = () => {
    setShowPopup(false);
  };

  // Startup images data
  const startupImages = [
    { src: sl1, alt: "Startup 1" ,link:"https://www.cooliewale.in/"},
    { src: sl2, alt: "Startup 2",link:"https://www.cashcry.com/" },
    { src: sl3, alt: "Startup 3" },
    { src: sl4, alt: "Startup 4" ,link:"https://gamingecosystem.in/"},
    { src: sl5, alt: "Startup 5",link:"https://shipizens.com/" },
    { src: sl6, alt: "Startup 6" },
    { src: sl7, alt: "Startup 7" },
    { src: sl8, alt: "Startup 8" },
    { src: sl9, alt: "Startup 9" ,link:"https://henixrehab.com/"},
    { src: sl10, alt: "Startup 10" },
  ];

  // Pre-incubated images data
  const preIncubatedImages = [
    { src: pis1, alt: "Preincubated Startup 1" },
    { src: pis2, alt: "Preincubated Startup 2" },
    { src: pis3, alt: "Preincubated Startup 3" },
    { src: pis4, alt: "Preincubated Startup 4" },
    { src: pis5, alt: "Preincubated Startup 5" },
    { src: pis6, alt: "Preincubated Startup 6" },
    { src: pis7, alt: "Preincubated Startup 7" },
    { src: pis8, alt: "Preincubated Startup 8" },
    { src: pis9, alt: "Preincubated Startup 9" },
  ];

  return (
    
    <div>
      {/* The popup should be rendered first to ensure the background can be blurred */}
      {showPopup && (
        <div className={`popup-container relative flex mt-[40px]`}>
          <div className="relative ml-4">
            <StartupSangamPopup closePopup={closePopup} />
          </div>
        </div>
      )}
      
      {/* Conditional class to blur the background */}
      <div className={`homepage ${showPopup ? "blurred" : ""}`}>
        <Navbar />
        <div className="nav-banner">
          <Carousel />
        </div>
        <Announcements />
        <hr />
        <About />
        <hr />
        <OurStartups images={startupImages} text="StartUps" />
        <hr />
        <OurStartups images={preIncubatedImages} text="Pre-Incubated Startups" />
        <hr />
        <FocusedAreas />
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
