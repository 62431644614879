"use client"
import React, { useState, useEffect } from 'react';
import { Rocket, Users, Globe, ChevronRight, X,Sprout } from 'lucide-react';
import './StartupSangamPopup.css'; // Import the CSS for animations

const StartupSangamPopup = ({ closePopup }) => {
  const [progress, setProgress] = useState(0);
  const [showPopup, setShowPopup] = useState(true); // Updated: Popup visibility state

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return 100;
        }
        return Math.min(oldProgress + 1, 100);
      });
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, []);

  // Conditionally render the popup based on showPopup state
  if (!showPopup) {
    return null; // If showPopup is false, don't render anything
  }

  return (
    <div className='animate' style={{
      backgroundColor: '#08415c',
      color: '#d0fcb3',
      padding: '1rem',
      borderRadius: '0.5rem',
      maxWidth: '20rem',
      margin: '0 auto',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      position: 'relative'
    }}>
      {/* Close button */}
      <button className="close-button" onClick={closePopup}>
        <X size={20} color="#d0fcb3" />
      </button>
      
      <h2 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '1rem', textAlign: 'center' }}>
        Welcome to MNNIT Startup Sangam
      </h2>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
        <Rocket size={30} color="#119da4" />
        <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>→</div>
        <Globe size={30} color="#119da4" />
      </div>

      <p style={{ textAlign: 'center', marginBottom: '1rem' }}>
        Connecting you to India's most vibrant startup ecosystem
      </p>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '1rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Users color="#119da4" />
          <span>Network with fellow entrepreneurs</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Rocket color="#119da4" />
          <span>Access resources to accelerate your startup</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Globe color="#119da4" />
          <span>Explore opportunities in the global market</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Sprout color="#119da4" />
          <span>Get incubated in our IIHMF</span>
        </div>
      </div>

      <div style={{ position: 'relative', paddingTop: '0.25rem', marginBottom: '1rem' }}>
        <div style={{
          overflow: 'hidden',
          height: '0.5rem',
          display: 'flex',
          borderRadius: '0.25rem',
          backgroundColor: 'rgba(17, 157, 164, 0.2)'
        }}>
          <div
            style={{
              width: `${progress}%`,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              backgroundColor: '#119da4'
            }}
          />
        </div>
      </div>

      <button style={{
        width: '100%',
        backgroundColor: '#119da4',
        color: '#d0fcb3',
        padding: '0.75rem',
        borderRadius: '0.5rem',
        fontWeight: '600',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
        transition: 'background-color 0.3s'
      }}
        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0d7a80'}
        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#119da4'}
      >
        <a style={{color: "#fff"}} href='https://startupsangam.iihmf.in/'><span>Enter Startup Sangam</span></a>
        <ChevronRight size={20} />
      </button>

    
    </div>
  );
};

export default StartupSangamPopup;